"use client";

import { ErrorMessage } from "@/components/ui/error-message";
import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";
export default function Error({
  error,
  reset
}: {
  error: Error & {
    digest?: string;
  };
  reset: () => void;
}) {
  useEffect(() => {
    // Report errors to Sentry if in production
    if (process.env.NODE_ENV === "production") {
      Sentry.captureException(error);
    }
  }, [error]);
  return <main className="flex h-full flex-col items-center justify-center" data-sentry-component="Error" data-sentry-source-file="error.tsx">
      <ErrorMessage title="Something went wrong" message="Please make sure you are logged in and have access to the page you are trying to reach. If the problem persists, please contact support." retry={reset} className="max-w-md" data-sentry-element="ErrorMessage" data-sentry-source-file="error.tsx" />
    </main>;
}