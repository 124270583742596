"use client";

import { Button } from "@/components/ui/button";
import { AlertCircle } from "lucide-react";
interface ErrorMessageProps {
  title?: string;
  message?: string;
  retry?: () => void;
  className?: string;
}
export function ErrorMessage({
  title = "Something went wrong",
  message = "An error occurred while loading this content. Please try again.",
  retry,
  className
}: ErrorMessageProps) {
  return <div className={`flex flex-col items-center justify-center gap-4 p-4 ${className}`} data-sentry-component="ErrorMessage" data-sentry-source-file="error-message.tsx">
      <div className="rounded-full bg-red-100/80 p-3 dark:bg-red-900/20">
        <AlertCircle className="h-6 w-6 text-red-600 dark:text-red-400" data-sentry-element="AlertCircle" data-sentry-source-file="error-message.tsx" />
      </div>
      <div className="text-center">
        <h3 className="text-lg font-semibold text-foreground">{title}</h3>
        <p className="mt-2 text-sm text-muted-foreground">{message}</p>
      </div>
      {retry && <Button variant="outline" size="sm" onClick={retry} className="mt-2">
          Try again
        </Button>}
    </div>;
}